<template>
  <el-dialog
      :append-to-body="true"
      :modal-append-to-body="false"
      :title="dialogTitle"
      :visible.sync="visible"
      width="80%"
      class="diaLogMessage"
      center
      :before-close="handleClose"
  >
    <span>{{ message }}</span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="sure">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'messageBox',
  props: {
    message: {
      type: String,
      default: ''
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    dialogTitle: {
      type: String,
      default: '提示'
    }
  },
  watch: {
    dialogVisible: {
      handler(value) {
        this.visible = value
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      visible: false
    }
  },
  methods: {
    sure: function() {
      this.$emit('messageBoxClose', { dialogVisible: false, result: true })
    },
    close: function() {
      this.$emit('messageBoxClose', { dialogVisible: false, result: false })
    },
    handleClose: function() {
      this.close()
    }
  }
}
</script>

<style lang="scss" scoped>

.diaLogMessage {
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  position: absolute;
}

</style>
