<template>
  <div class="fillterBGView">

    <div v-if="showViewStyle" class="title">
      记录类型
    </div>
    <v-stack v-if="showViewStyle" class="content" align-items="flex-start">
      <div :class="{'recordItem':true, 'selectItem':options.viewStyleId==item.id}"
           @click="selectViewStyle(item.id)"
           v-for="(item) in options.viewStyle"
      >
        {{ item.title }}
      </div>
    </v-stack>

    <div v-if="showTimeType" class="title">
      时间类型
    </div>
    <v-stack v-if="showTimeType" class="content" align-items="flex-start">
      <div :class="{'recordItem':true, 'selectItem':options.timeId==item.id}"
           @click="selectTimeType(item.id)"
           v-for="(item) in options.timeStyle"
      >
        {{ item.title }}
      </div>
    </v-stack>

    <div v-if="showRecord" class="title">
      记录类型
    </div>
    <v-stack v-if="showRecord" class="content" align-items="flex-start">
      <div :class="{'recordItem':true, 'selectItem':options.recordId==item.id}"
           @click="selectTimeType(item.id)"
           v-for="(item) in options.record"
      >
        {{ item.title }}
      </div>
    </v-stack>

    <div v-if="showSelectTime" class="title">
      时间范围
    </div>
    <h-stack v-if="showSelectTime" class="content" flex-wrap="wrap" justify-content="flex-start">
      <div
          @click="selectTime(item)"
          :class="{'timeSelect':true, 'selectItem':options.timeSelectId==item.id}"
          v-for="(item) in options.timeSelect"
      >
        {{ item.title }}
      </div>
      <div v-show="options.timeSelectId=='bySelf'"
           :class="{'selfTime':true, 'selectSelfTimeItem':options.timeSelectId=='bySelf'}"
           @click="addDevice('timeSelect')"
      >
        {{ selfTime }}
      </div>
    </h-stack>

    <div v-if="showStation" class="title">
      基站MAC
      <el-button type="text" class="addButton" @click="addDevice('baseStations')">添加</el-button>
    </div>

    <ul v-if="showStation" class="deviceUl">
      <li class="deviceLi" v-for="(item) in options.baseStations">
        <h-stack justify-content="space-between" align-items="flex-start">
          <div
              @click="changeStatus(item)"
              :class="{'deviceContent':true, 'deviceNotUse':item.type==3, 'deviceInclude':item.type==1, 'deviceExclude':item.type==2}"
          >
            <div
                :class="{'typeTitle':true, 'deviceNotUseTitle':item.type==3, 'deviceIncludeTitle':item.type==1, 'deviceExcludeTitle':item.type==2}"
            >
              {{ deviceStatusMsg(item) }}
            </div>
            <div
                :class="{'deivceContent':true, 'deviceNotUseContent':item.type==3, 'deviceIncludeContent':item.type==1, 'deviceExcludeContent':item.type==2}"
            >
              {{ item.title }}
            </div>
          </div>
          <div class="deviceDelete" @click="deleteDevice(item, 'baseStations')">
            删除
          </div>
        </h-stack>
      </li>
    </ul>

    <div v-if="showDevice" class="title">
      设备MAC
      <el-button type="text" class="addButton" @click="addDevice('devices')">添加</el-button>
    </div>

    <ul v-if="showDevice" class="deviceUl">
      <li class="deviceLi" v-for="(item) in options.devices">
        <h-stack justify-content="space-between" align-items="flex-start">
          <div
              @click="changeStatus(item)"
              :class="{'deviceContent':true, 'deviceNotUse':item.type==3, 'deviceInclude':item.type==1, 'deviceExclude':item.type==2}"
          >
            <div
                :class="{'typeTitle':true, 'deviceNotUseTitle':item.type==3, 'deviceIncludeTitle':item.type==1, 'deviceExcludeTitle':item.type==2}"
            >
              {{ deviceStatusMsg(item) }}
            </div>
            <div
                :class="{'deivceContent':true, 'deviceNotUseContent':item.type==3, 'deviceIncludeContent':item.type==1, 'deviceExcludeContent':item.type==2}"
            >
              {{ item.title }}
            </div>
          </div>
          <div class="deviceDelete" @click="deleteDevice(item, 'devices')">
            删除
          </div>
        </h-stack>
      </li>
    </ul>

    <message-box @messageBoxClose="messageBoxClose"
                 :dialog-visible="showMessageBox"
                 message="是否删除该设备"
    >

    </message-box>
    <dialog-in-put @dialogClose="dialogClose"
                   :content="dialogInput"
                   :message="dialogMessage"
                   :match-type="matchType"
                   :dialog-visible="showInPut"
    >
    </dialog-in-put>


  </div>
</template>

<script>
import VStack from '@/components/vStack/vStack'
import HStack from '@/components/hStack/hStack'
import DialogInPut from '@/components/inPut/dialogInPut'
import MessageBox from '@/components/messageBox/messageBox'

export default {
  name: 'fillterView',
  components: { MessageBox, DialogInPut, HStack, VStack },
  props: {
    viewConfig: {
      type: Object,
      default: () => {
        return {
          recode: '',
          selectTime: '',
          selectStation: '',
          selectDevice: ''
        }
      }
    },
  },
  watch: {
    viewConfig: {
      handler(value) {
        if (typeof value['showMega'] == 'undefined') {
          if (this.options.viewStyle.length == 3) {
           let list = [this.options.viewStyle[0], this.options.viewStyle[1]];
            this.options.viewStyle = list;
          }
          return
        }
        let itemInfo = { title: '磁感图表', id: 'mega', key: 'mega' }
        this.options.viewStyle.push(itemInfo)
      },
      immediate: true,
      deep: true
    }
  },
  data() {
    return {
      showMessageBox: false,
      showMessageBlock: null,
      showInPut: false,
      inPutType: '',
      viewOptions: {
        record: false,
        selfTime: false,
        station: false,
        device: false
      },
      options: {
        timeStyle: [
          { title: '按天', id: 'day', key: 'day' },
          { title: '按小时', id: 'hour', key: 'hour' }
        ],
        timeId: 'day',
        viewStyle: [
          { title: '列表', id: 'list', key: 'list' },
          { title: 'RSSI图表', id: 'rssi', key: 'rssi' }
        ],
        viewStyleId: 'list',
        record: [
          { title: '设备全部记录', id: 'allRecord', key: 'allRecord' },
          { title: '设备最新一条记录', id: 'theNewRecord', key: 'theNewRecord' }
        ],
        recordId: 'allRecord',
        timeSelect: [{
          title: '30秒',
          id: '30s',
          value: 30,
          type: 'option'
        }, {
          title: '15分钟',
          id: '15min',
          value: 900,
          type: 'option'
        }, {
          title: '30分钟',
          id: '30min',
          value: 1800,
          type: 'option'
        }, {
          title: '自定义',
          id: 'bySelf',
          value: 1800,
          type: 'bySelf'
        }],
        timeSelectId: '30s',
        baseStations: [],
        devices: []
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getFillterInfo()

    })
  },
  computed: {
    showViewStyle: function() {
      let show = false
      if (typeof this.viewConfig['viewStyle'] != 'undefined') {
        show = true
      }
      return show
    },
    showTimeType: function() {
      let show = false
      if (typeof this.viewConfig['timeStyle'] != 'undefined') {
        show = true
      }
      return show
    },
    showRecord: function() {
      let show = false
      if (typeof this.viewConfig['recode'] != 'undefined') {
        show = true
      }
      return show
    },
    showSelectTime: function() {
      let show = false
      if (typeof this.viewConfig['selectTime'] != 'undefined') {
        show = true
      }
      return show
    },
    showStation: function() {
      let show = false
      if (typeof this.viewConfig['selectStation'] != 'undefined') {
        show = true
      }
      return show
    },
    showDevice: function() {
      let show = false
      if (typeof this.viewConfig['selectDevice'] != 'undefined') {
        show = true
      }
      return show
    },
    dialogMessage: function() {
      if (this.inPutType == 'timeSelect') {
        return '请输入自定义时间,单位(s)'
      } else if (this.inPutType == 'devices' || this.inPutType == 'baseStations') {
        return '请输入设备内容'
      }
      return ''
    },
    dialogInput: function() {
      if (this.inPutType == 'timeSelect') {
        let option = this.options.timeSelect[this.options.timeSelect.length - 1]
        return option.value + ''
      }
      return ''
    },
    selfTime: function() {
      let option = this.options.timeSelect[this.options.timeSelect.length - 1]
      return this.getTimeMsg(option.value, 1)
    },
    matchType: function() {
      if (this.inPutType == 'timeSelect') {
        return 'onlyInt'
      } else if (this.inPutType == 'devices' || this.inPutType == 'baseStations') {
        return 'onlyUUID'
      }
      return '.'
    }
  },
  methods: {
    selectTimeType: function(id) {
      if (this.options.timeId == id) {
        return
      }
      this.options.timeId = id
      this.updateSearch()
    },
    // 选择列表样式
    selectViewStyle: function(id) {
      if (this.options.viewStyleId == id) {
        return
      }
      this.options.viewStyleId = id
      this.updateSearch()
    },
    addDevice: function(type) {
      this.showInPut = true
      this.inPutType = type
    },
    messageBoxClose: function(res) {
      this.showMessageBox = false
      if (res.result && this.showMessageBlock != null) {
        this.showMessageBlock(res)
      } else {
        this.showMessageBlock = null
      }
    },
    deleteDevice: function(item, key) {
      this.showMessageBlock = (res => {
        this.showMessageBlock = null
        if (typeof this.options[key] == 'undefined' && typeof this.options[key]['length'] == 'undefined') {
          return
        }
        let list = this.options[key]
        let newList = []
        list.map((a) => {
          if (a.id != item.id) {
            newList.push(a)
          }
        })
        this.options[key] = newList
        this.updateSearch()
      })
      this.showMessageBox = true
    },
    deviceStatusMsg: function(item) {
      if (item.type == 1) {
        return '包含'
      } else if (item.type == 2) {
        return '排除'
      } else if (item.type == 3) {
        return '不使用'
      } else {
        return ''
      }
    },
    changeStatus: function(item) {
      item.type = item.type + 1
      if (item.type > 3) {
        item.type = 1
      }
      this.updateSearch()
    },
    dialogClose: function(res) {
      let updateDataList = (list, value) => {
        for (let i = 0; i < list.length; i++) {
          let info = list[i]
          if (info.title == res.input) {
            this.$message({
              type: 'warning',
              message: '已经存在该记录'
            })
            return
          }
        }
        list.push({
          title: value,
          id: value,
          type: 1
        })
        this.updateSearch()
      }

      this.showInPut = false
      if (res.result == false) {
        return
      }
      if (this.inPutType == 'timeSelect') {
        let option = this.options.timeSelect[this.options.timeSelect.length - 1]
        option.value = parseInt(res.input)
        this.updateSearch()
      } else if (this.inPutType == 'baseStations') {
        updateDataList(this.options.baseStations, res.input)
      } else if (this.inPutType == 'devices') {
        updateDataList(this.options.devices, res.input)
      }
    },

    selectTime: function(item) {
      this.inPutType = ''
      if (this.options.timeSelectId == item.id) {
        return
      }
      this.options.timeSelectId = item.id
      this.updateSearch()
    },
    selectDeviceRecord: function(id) {
      if (this.options.recordId == id) {
        return
      }
      this.options.recordId = id
      this.$emit('')
    },
    getFillterInfo: function() {
      if (typeof localStorage['fillter'] == 'undefined') {
        return
      }
      let infoStr = localStorage['fillter']
      try {
        let updateRecord = (info) => {
          for (let i = 0; i < this.options.record.length; i++) {
            let record = this.options.record[i]
            if (record.id == info.id) {
              this.options.recordId = info.id
              break
            }
          }
        }

        let updateTime = (info, delayRefreshTime) => {
          for (let i = 0; i < this.options.timeSelect.length; i++) {
            let time = this.options.timeSelect[i]
            if (time.id == info.id) {
              this.options.timeSelectId = info.id
              time.value = delayRefreshTime
              break
            }
          }
        }

        let updateDataList = (list, info) => {
          list.push({
            title: info.id,
            id: info.id,
            type: info.status
          })
        }

        let info = JSON.parse(infoStr)
        for (let i = 0; i < info.fillterList.length; i++) {
          let dataInfo = info.fillterList[i]
          if (dataInfo.type == 'record') {
            updateRecord(dataInfo)
          } else if (dataInfo.type == 'time') {
            updateTime(dataInfo, dataInfo.value)
          } else if (dataInfo.type == 'station') {
            updateDataList(this.options.baseStations, dataInfo)
          } else if (dataInfo.type == 'device') {
            updateDataList(this.options.devices, dataInfo)
          } else if (dataInfo.type == 'timeType') {
            this.options.timeId = dataInfo.id
          } else if (dataInfo.type == 'viewType') {
            this.options.viewStyleId = dataInfo.id
          }
        }
      } catch (e) {

      }
    },
    updateSearch: function() {
      let fillterInfo = {
        fillterList: []
      }
      for (let i = 0; i < this.options.record.length; i++) {
        let info = this.options.record[i]
        if (this.options.recordId == info.id) {
          fillterInfo.fillterList.push({
            title: info.title,
            id: info.id,
            type: 'record'
          })
          break
        }
      }

      for (let i = 0; i < this.options.timeSelect.length; i++) {
        let info = this.options.timeSelect[i]
        if (this.options.timeSelectId == info.id) {
          fillterInfo.delayRefreshTime = info.value
          fillterInfo.fillterList.push({
            title: info.title + (info.id == 'bySelf' ? this.selfTime : ''),
            id: info.id,
            type: 'time',
            value: info.value
          })
          break
        }
      }

      for (let i = 0; i < this.options.baseStations.length; i++) {
        let info = this.options.baseStations[i]
        if (info.type == 3) {
          continue
        }
        fillterInfo.fillterList.push({
          title: '基站' + this.deviceStatusMsg(info) + ' ' + info.title,
          id: info.id,
          type: 'station',
          status: info.type
        })
      }

      for (let i = 0; i < this.options.devices.length; i++) {
        let info = this.options.devices[i]
        if (info.type == 3) {
          continue
        }
        fillterInfo.fillterList.push({
          title: '设备' + this.deviceStatusMsg(info) + ' ' + info.title,
          id: info.id,
          type: 'device',
          status: info.type
        })
      }

      for (let i = 0; i < this.options.timeStyle.length; i++) {
        let info = this.options.timeStyle[i]
        if (info.id != this.options.timeId) {
          continue
        }
        fillterInfo.fillterList.push({
          title: info.title,
          id: info.id,
          type: 'timeType'
        })
      }

      for (let i = 0; i < this.options.viewStyle.length; i++) {
        let info = this.options.viewStyle[i]
        if (info.id != this.options.viewStyleId) {
          continue
        }
        fillterInfo.fillterList.push({
          title: info.title,
          id: info.id,
          type: 'viewType'
        })
      }

      this.$emit('updateSearch', fillterInfo)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.fillterBGView {
  width: 100%;
  height: 100%;
  overflow: scroll;


  .title {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    padding-left: 10px;
    background-color: #eeeeee;

    .addButton {
      float: right;
      margin-right: 10px;
      height: 50px;
    }

  }

  .content {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .recordItem {
    max-width: 70%;
    height: 35px;
    line-height: 35px;
    font-size: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: gray;
    border-radius: 5px;
    border: 1px gray solid;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 10px;
  }


  .timeSelect {
    width: calc(100% / 3.0 - 10px);
    height: 40px;
    margin: 5px;
    line-height: 40px;
    color: gray;
    border-radius: 5px;
    border: 1px gray solid;
    text-align: center;
  }

  .selfTime {
    width: calc(100% * 2 / 3.0 - 10px);
    height: 40px;
    margin: 5px;
    line-height: 40px;
    color: gray;
    border-bottom: 1px gray solid;
    text-align: center;
  }

  .selectSelfTimeItem {
    color: $color_primary;
    border-bottom: 1px $color_primary solid;
  }

  .selectItem {
    color: $color_primary;
    border: 1px $color_primary solid;
  }

  .deviceUl {
    list-style: none;
    padding: 0;
    margin-top: 5px;
    margin-bottom: 10px;

    .deviceLi {
      width: 100%;
      margin-top: 10px;

      .deviceExclude {
        border: 1px red solid;

        .deviceExcludeTitle {
          color: red;
        }

        .deviceExcludeContent {
          color: red;
        }
      }

      .deviceInclude {
        border: 1px $color_primary solid;

        .deviceIncludeTitle {
          color: $color_primary;
        }

        .deviceIncludeContent {
          color: $color_primary;
        }
      }

      .deviceNotUse {
        border: 1px gray solid;

        .deviceNotUseTitle {
          color: gray;
        }

        .deviceNotUseContent {
          color: gray;
        }
      }

      .deviceContent {
        margin-left: 5px;
        width: calc(100% - 50px);
        min-height: 30px;
        padding: 5px;
        border-radius: 5px;

        .typeTitle {
          height: 30px;
          line-height: 30px;
          font-size: 15px;
          width: 60px;
          float: left;
        }

        .deivceContent {
          width: calc(100% - 65px);
          line-height: 30px;
          font-size: 15px;
          float: right;
          word-wrap: break-word;
          text-align: right;
        }

      }

      .deviceDelete {
        margin-top: 5px;
        margin-right: 5px;
        width: 50px;
        height: 30px;
        line-height: 30px;
        font-size: 15px;
        color: gray;
        text-align: right;
      }

    }
  }


}

</style>
