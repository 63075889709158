import { Loading } from 'element-ui'

let mixin = {
  data: function() {
    return {
      loadingData: false,
      resetPage: false,
      loading: true,
      showDetailView: false, // 消息弹窗
      deviceInfo: {},
      showFillterView: false, // 消息过滤
      tabList: [],
      activeName: '',
      fillterInfo: {},
      fillterConfig: {}
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.updateStyle()
      this.getFillterInfo()
      this.getTabList().then(res => {
        if (res.result == false) {
          return
        }
        if (typeof this.afterGetTabList == 'undefined') {
          return
        }
        this.afterGetTabList()
      })
    })
  },
  props: {},
  computed: {},
  watch: {
    fillterInfo: {
      handler(value) {
        // if (value.delayRefresh == false) {
        //   return
        // }
        let res = this.updateFillterConfig()
        if (res.result == false) {
          return
        }
        if (typeof this.afterUpdateing != 'undefined') {
          this.afterUpdateing()
        }
        this.loading = false
      },
      immediate: true,
      deep: true
    }
  },
  beforeDestroy() {

  },
  methods: {
    loadingChange: function(res) {
      this.loadingData = res.result
    },
    // 从弹窗过滤设备
    fillterAction: function(info) {
      if (typeof this.fillterInfo == 'undefined' || this.fillterInfo == null) {
        return
      }
      let fillterList = []
      if (typeof this.fillterInfo != 'undefined' && this.fillterInfo != null) {
        fillterList = this.fillterInfo.fillterList
      } else {
        this.fillterInfo['fillterList'] = fillterList
      }
      for (let i = 0; i < fillterList.length; i++) {
        let data = fillterList[i]
        if (data.id == info.id && data.type == info.type) {
          return
        }
      }
      fillterList.push(info)
      localStorage.setItem('fillter', JSON.stringify(this.fillterInfo))
      if (typeof this.$refs['fillterView'] == 'undefined' || this.$refs['fillterView'] == null) {
        return
      }
      this.$refs['fillterView'].getFillterInfo()
    },
    deviceMsgBoxBGViewClose: function(res) {
      this.showDetailView = false
    },
    stopRefresh: function() {
      this.delayRefresh({ result: false })
    },
    // 点击设备消息
    clickItem: function(item) {
      let title = ''
      for (let i = 0; i < this.tabList.length; i++) {
        let info = this.tabList[i]
        if (!info.show) {
          continue
        }
        title = info.title
      }
      item['title'] = title
      this.deviceInfo = item
      this.showDetailView = true
    },
    // 更新tab上的消息数量
    updateNumber: function(res) {
      for (let i = 0; i < this.tabList.length; i++) {
        let tab = this.tabList[i]
        if (tab.title != res.type) {
          continue
        }
        tab.number = res.totalCount
      }
    },
    // 点击tab
    handleClick(tab, event) {
      for (let i = 0; i < this.tabList.length; i++) {
        let info = this.tabList[i]
        if (info.activeName == info.title) {
          return
        }
        info.show = this.activeName == info.title
        if (info.show) {
          this.$router.replace(info.router)
        }
      }
      if (typeof this.afterGetTabList == 'undefined') {
        return
      }
      this.afterGetTabList()
    },
    updateStyle: function() {
      let classStyles = document.getElementsByClassName('el-tabs__content')
      for (let i = 0; i < classStyles.length; i++) {
        let s = classStyles[i]
        s.style.height = 'calc(100% - 55px)'
      }
    },
    delayRefresh: function(res) {
      this.fillterInfo.delayRefresh = res.result
      localStorage.setItem('fillter', JSON.stringify(this.fillterInfo))
    },
    // 强制刷新
    refresh: function() {
      this.delayRefresh({ result: true })
    },
    // 显示过滤界面
    showFillter: function() {
      this.showFillterView = true
    },
    updateSearch: function(res) {
      let fillterList = []
      if (typeof res['fillterList'] != 'undefined') {
        fillterList = res['fillterList']
      }
      this.fillterInfo['fillterList'] = fillterList
      this.fillterInfo.delayRefreshTime = 10
      localStorage.setItem('fillter', JSON.stringify(this.fillterInfo))
      if (typeof this.afterUpdateing == 'undefined') {
        return
      }
      this.afterUpdateing()
    },
    updateFillterConfig: function() {
      this.fillterConfig = {}
      if (typeof this.fillterInfo.fillterList == 'undefined' || this.fillterInfo.fillterList == null) {
        return {
          result: false
        }
      }
      this.fillterConfig['order'] = 1

      for (let i = 0; i < this.fillterInfo.fillterList.length; i++) {
        let info = this.fillterInfo.fillterList[i]
        if (info.type == 'time') {
          this.fillterConfig['time'] = info.value
        } else if (info.type == 'device') {
          if (info.status == 3) {
            continue
          }
          let key = info.status == 1 ? 'includeMac' : 'excludeMac'
          let deviceStr = ''
          if (typeof this.fillterConfig[key] != 'undefined' && this.fillterConfig[key] != null) {
            deviceStr = this.fillterConfig[key]
          }
          deviceStr = deviceStr + (deviceStr == '' ? '' : ',') + info.id
          this.fillterConfig[key] = deviceStr
        } else if (info.type == 'station') {
          if (info.status == 3) {
            continue
          }
          let key = info.status == 1 ? 'includeBaseStationMac' : 'excludeBaseStationMac'
          let deviceStr = ''
          if (typeof this.fillterConfig[key] != 'undefined' && this.fillterConfig[key] != null) {
            deviceStr = this.fillterConfig[key]
          }
          deviceStr = deviceStr + (deviceStr == '' ? '' : ',') + info.id
          this.fillterConfig[key] = deviceStr
        }
      }
      return {
        result: true
      }
    },
    startRefresh:function() {
      let count = 0
      if (typeof this.fillterConfig['n'] != 'undefined') {
        count = this.fillterConfig['n'] + 1
        if (count >= 1000) {
          count = 0
        }
      }
      this.fillterConfig['n'] = count
      this.fillterConfig = JSON.parse(JSON.stringify(this.fillterConfig))
    },
    // 更新tab
    getTabList: function() {
      let getDataList = () => {
        return new Promise(resolve => {
          this.updateTabList((res) => {
            resolve(res)
          })
        })
      }

      return new Promise(resolve => {
        getDataList().then(res => {
          this.tabList = res.dataList
          if (this.tabList.length == 0) {
            resolve({ result: false })
            return
          }
          let name = ''
          if (typeof this.$route.query['name'] != 'undefined' && this.$route.query['name'] != null) {
            name = this.$route.query['name']
          }
          let info = this.tabList[0]
          if (name != '') {
            for (let i = 0; i < this.tabList.length; i++) {
              let data = this.tabList[i]
              if (data.title == name) {
                info = data;
                break;
              }
            }
          }
          info.show = true
          this.activeName = info.title
          if (this.$route.path == info.router) {
            resolve({ result: true })
            return
          }
          this.$router.replace(info.router)
          resolve({ result: true })
        })
      })
    },
    getFillterInfo: function() {
      if (typeof localStorage['fillter'] == 'undefined') {
        let fillterInfo = {
          fillterList: [{
            title: '设备全部记录',
            id: 'allRecord',
            type: 'record'
          }, {
            title: '30秒',
            id: '30s',
            type: 'time',
            value: 30
          }],
          delayRefresh: false,
          delayRefreshTime: 10,
          selfRefresh: true
        }
        localStorage.setItem('fillter', JSON.stringify(fillterInfo))
        this.fillterInfo = fillterInfo
        return
      }
      let infoStr = localStorage['fillter']
      try {
        let info = JSON.parse(infoStr)
        this.fillterInfo = info
      } catch (e) {

      }
    }
  }
}

export default mixin
