<template>
  <div class="fillterBar">
    <div class="fillterLine"></div>
    <div class="fillterMsgShow" @click="showFillter">
      <img src="../../../../static/iconImage/guolv.png" class="fillterImg"/>
      <h-stack class="fillterContent" justify-content="flex-start">
        <div :class="itemClass(item)" v-for="(item) in fillterList">
          {{ item.title }}
        </div>
      </h-stack>
    </div>
    <div class="fillterLine"></div>
    <div class="fillterRefresh">
      <!--      <el-switch-->
      <!--          class="switch"-->
      <!--          v-model="fillterInfo.delayRefresh"-->
      <!--          :active-text="fillterInfo.delayRefresh ? getTimeMsg(fillterInfo.delayRefreshTime, 1) + '后刷新' :'自动刷新'"-->
      <!--          active-color="#13ce66"-->
      <!--          inactive-color="#aaaaaa"-->
      <!--      >-->
      <!--      </el-switch>-->
      <el-switch
          class="switch"
          v-model="fillterInfo.delayRefresh"
          :active-text="fillterInfo.delayRefresh ? leftTimeRefreshStr :'自动刷新'"
          active-color="#13ce66"
          inactive-color="#aaaaaa"
      >
      </el-switch>
      <div id="loadingView" class="loadingView"></div>
      <div class="selfRefresh" @click.stop="refresh">
        刷新
      </div>
    </div>
  </div>
</template>

<script>
import HStack from '@/components/hStack/hStack'
import { Loading } from 'element-ui'

export default {
  name: 'fillterBar',
  components: { HStack },
  props: {
    loading:{
      default:false,
    },
    fillterInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }, // 搜索条件
    fillterKeyInfo: {
      type: Object,
      default: () => {
        return { 'record': '', 'time': '', 'station': '', 'device': '' }
      }
    } // 显示搜索条件的key
  },
  computed: {
    leftTimeRefreshStr: function() {
      let count = this.leftTimeRefresh
      if (count == 0) {
        return '开始刷新'
      }
      return count + '秒后刷新'
    },
    leftTimeRefresh: function() {
      if (typeof this.fillterInfo.delayRefreshTime == 'undefined' || this.fillterInfo.delayRefreshTime == null) {
        return 10
      }
      if (this.timeCount == null) {
        this.timeCount = this.fillterInfo.delayRefreshTime
      }
      return this.timeCount
    },
    fillterList: function() {
      let list = []
      if (typeof this.fillterInfo.fillterList == 'undefined' || this.fillterInfo.fillterList == null) {
        return list
      }
      for (let i = 0; i < this.fillterInfo.fillterList.length; i++) {
        let info = this.fillterInfo.fillterList[i]
        if (typeof this.fillterKeyInfo[info.type] == 'undefined') {
          continue
        }
        list.push(info)
      }
      return list
    }
  },
  data() {
    return {
      loadingView: null,
      timer:null,
      timeCount: null
    }
  },
  watch: {
    'fillterInfo': {
      handler(value) {
      },
      immediate: true,
      deep: true
    },
    'fillterInfo.delayRefresh': {
      handler(value) {
        if (typeof value == 'undefined' || value == null) {
          return
        }
        this.$emit('delayRefresh', { result: value })
        if (value) {
          this.openTimer(1);
        } else {
          this.closeTimer();
        }
      },
      immediate: true,
      deep: true
    },
    loading: {
      handler(value) {
        if (value) {
          this.startLoading()
        } else {
          if (this.loadingView == null) {
            return
          }
          this.loadingView.close()
        }
      },
      immediate: true,
      deep: true
    },
  },
  mounted() {
    this.$nextTick(() => {

    })
  },
  beforeDestroy() {
    this.closeTimer();
  },
  methods: {
    startLoading: function() {
      let dom = document.getElementById('loadingView')
      if (typeof dom == 'undefined' || dom == null) {
        return
      }
      this.loadingView = Loading.service({ target: dom })
    },
    closeTimer:function() {
      this.timeCount = 0
      if (this.timer == null) {
        return;
      }
      clearInterval(this.timer);
      this.timer = null;
    },
    openTimer:function(time) {
      if (this.timer != null) {
        return;
      }
      this.timer = setInterval(()=>{
        let count = this.timeCount - 1;
        if (count < 0) {
          if (typeof this.fillterInfo.delayRefreshTime == 'undefined' || this.fillterInfo.delayRefreshTime == null) {
            count = 10;
          } else {
            count = this.fillterInfo.delayRefreshTime;
          }
        }
        this.timeCount = count;
        if (count == 0) {
          this.$emit('startRefresh', {});
        }
      }, time * 1000)
    },
    refresh: function() {
      // this.$emit('refresh', {})
      this.fillterInfo.delayRefresh = true
      this.closeTimer();
      this.openTimer(1);
      this.$emit('startRefresh', {});
    },
    itemClass: function(item) {
      let classInfo = {
        'fillterItem': true, 'normal': true, 'exclude': false, 'notUse': false
      }
      if (item.type != 'device' && item.type != 'station') {
        return classInfo
      }
      if (item.status == 1) {
        return classInfo
      } else if (item.status == 2) {
        classInfo.normal = false
        classInfo.exclude = true
        return classInfo
      } else {
        classInfo.normal = false
        classInfo.notUse = true
        return classInfo
      }
    },
    showFillter: function() {
      this.$emit('showFillter', {})
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.fillterBar {
  width: 100%;
  height: 100px;

  .fillterMsgShow {
    width: 100%;
    height: 50px;

    .fillterImg {
      width: 32px;
      height: 32px;
      margin-left: 10px;
      float: left;
      transform: translate(0px, 9px);
    }

    .fillterContent {
      margin-left: 5px;
      margin-right: 5px;
      width: calc(100% - 55px);
      height: 50px;
      float: right;
      overflow: scroll;

      .exclude {
        border: 1px red solid;
        color: red;
      }

      .notUse {
        border: 1px gray solid;
        color: gray;
      }

      .normal {
        color: $color_primary;
        border: 1px $color_primary solid;
      }

      .fillterItem {
        white-space: nowrap;
        height: 30px;
        line-height: 30px;
        font-size: 15px;
        border-radius: 5px;
        margin-left: 5px;
        padding-left: 5px;
        padding-right: 5px;
      }

    }

  }

  .fillterLine {
    width: 95%;
    height: 1px;
    background-color: #cccccc;
  }

  .fillterRefresh {
    width: 100%;
    height: 50px;

    .switch {
      height: 100%;
      margin-left: 10px;
      float: left;
    }

    .loadingView {
      width: 40px;
      height: 40px;
      float: left;
      transform: translate(5px, 5px);
    }

    .selfRefresh {
      margin: 5px;
      height: 40px;
      line-height: 40px;
      font-size: 20px;
      width: 70px;
      float: right;
      border-radius: 5px;
      color: $color_primary;
      border: 1px $color_primary solid;
      text-align: center;
    }

  }

}

</style>
